import React, { useState } from "react";

function App() {
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState("");
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    // Handle image file selection
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile && selectedFile.type.startsWith("image/")) {
            setFile(selectedFile);
        } else {
            alert("Please upload a valid image file (PNG, JPG, etc.).");
        }
    };

    // Handle form submission
    const handleSubmit = async (event) => {
      event.preventDefault();
      if (!file || !description) {
          alert("Please upload an image and enter a description.");
          return;
      }

      setLoading(true);

      const descriptionBlob = new Blob([description], { type: "text/plain" });

      // Create FormData object to send file + description
      const formData = new FormData();
      formData.append("file", file);
      // formData.append("description", description);
      formData.append("description", descriptionBlob);

      try {
          const response = await fetch("http://10.200.248.186:5003/predict", {
              method: "POST",
              body: formData, // Sending multipart/form-data
              mode: "cors",  // Prevents CORS issues
          });

          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const result = await response.json();
          setResponse(result);
      } catch (error) {
          console.error("Fetch error:", error);
          alert("Failed to send data." + error);
      }

      setLoading(false);
    };  

    return (
        <div style={{ maxWidth: "500px", margin: "50px auto", textAlign: "center" }}>
            <h2>Upload Image & Get Response</h2>

            <form onSubmit={handleSubmit}>
                <input type="file" accept="image/*" onChange={handleFileChange} />
                <br /><br />
                <input
                    type="text"
                    placeholder="Enter description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{ padding: "10px", width: "100%" }}
                />
                <br /><br />
                <button type="submit" disabled={loading} style={{ padding: "10px 20px" }}>
                    {loading ? "Processing..." : "Submit"}
                </button>
            </form>

            {response && (
                <div style={{ marginTop: "20px", padding: "10px", border: "1px solid #ccc" }}>
                    <h3>Response:</h3>
                    <pre>{JSON.stringify(response, null, 2)}</pre>
                </div>
            )}
        </div>
    );
}

export default App;
